import React, {useState} from 'react'
import { FaBars, FaTimes} from 'react-icons/fa'
import './Navbar.css';
import { Link } from 'react-scroll';

const Navbar = () => {

const [click, setClick] = useState (false)
const handleClick = () => setClick(!click)


  return (
    <div className='header'>
        <div className='container'>
            <h1>Axi<span className="primary">Ops</span></h1>
            <ul  className={click ? 'nav-menu active' : 'nav-menu'}>
                <li>
                    <Link to='home' span={true} smooth={true}>
                    <a href='/'>Home</a>
                    </Link>
                </li>
                <li>
                    <Link to='featured' span={true} smooth={true}>
                    <a href='/'>Featured</a>
                    </Link>
                </li>
                <li>
                    <Link to='earn' span={true} smooth={true}>
                    <a href='/'>Earn</a>
                    </Link>
                </li>
                <li>
                    <Link to='footer' span={true} smooth={true}>
                    <a href='/'>Contact</a>
                    </Link>
                </li>
                
            </ul>
            <div className="btn-group">
                <button className="btn">Connect  Wallet</button>
            </div>
          <div className='hamburger' onClick={handleClick}>
             {click ? (<FaTimes />) : (<FaBars size={20} style={{color: '#333'}}/>)}
              
          </div>

        </div>
    </div>
  )
}

export default Navbar