import React from 'react'
import './Hero.css'
import Crypto from '../assets/hero-img.png'

const Hero = () => {
  return (
    <div className="hero" id='home'>
        <div className="container">
          {/* Left Side */}
            <div className="left" >
                <p>Buy $ Sell Crypto 24/7 using you retirement account</p>
                <h1>Invest in Cryptocurrency with Your IRA</h1>
                <p>Buy, Sell, and store hundreds of Cryptocurrencies</p>
                <div className="input-container">
                    <input type='email'  placeolder="Enter your email" />
                        <button className='btn'>Learn More</button>
                </div>
            </div>

          {/* Right Side */}
            <div className="right">
                <img  src={Crypto} alt="" />
            </div>
          

        </div>
        
    </div>
  )
}

export default Hero